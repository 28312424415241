body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main {
  flex: 1 0 auto;
  min-height: 100%;
}
.ant-btn-primary {
  background: #26a69a;
  border-color:#26a69a;
}
.ant-btn-primary:active {
  color: #fff;
  background: #26a69a;
  border-color: #26a69a;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #26a69a;
  border-color: #26a69a;
}
h5 { 
  color: #0D47A1;
}

.page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: blue;
  color: white;
  text-align: center;
}
